import React from 'react'
import { connect } from 'react-redux'
import { findGrocer } from '../../grocer/index'
import { GROCER } from './settingsConstants'
import { generateId } from '../../js-lib/storage/idGenerator'
import ShoppingItemSelector from '../selector'
import GrocerShoppingItem from '../domain/grocerShoppingItem'
import GrocerProductSelector from '../../grocer/selector'
import { formatCurrency } from '../../js-lib/currency-formatting'

class Plan extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleAdd(grocerProduct) {
    const item = new GrocerShoppingItem({ id: generateId(), quantity: 1 }, grocerProduct)
    window.storageService.store('shoppingItem', [item.toStorageFormat()])
  }

  renderProduct(product, showSavings) {
    return (
      <div className="shopping-list-item">
        <div style={{ display: 'flex' }}>
          <div>{product.image && <img src={product.image} alt={product.name} style={{ padding: '5px' }} width="70px" />}</div>
          <div style={{ padding: '5px', width: '100%' }}>
            <p>{product.name}</p>
            <small>
              {!!product.price && <span>{formatCurrency('AUD', product.price * 100)} </span>}
              {showSavings && <span> (was {formatCurrency('AUD', product.previousPrice * 100)}) </span>}
              {product.url && <a href={product.url}>{new URL(product.url).hostname.replace('www.', '')}</a>}
              <div style={{ float: 'right' }}>
                <button onClick={(e) => this.handleAdd(product)} style={{ marginLeft: '5px' }} className="button is-small">
                  Add
                </button>
              </div>
            </small>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { props, state } = this
    const grocerProductIdsAlreadyOnList = props.grocerItemsOnList.map((gi) => gi.grocerProduct.grocerProductId)
    const specials = props.allGrocersProducts
      .filter((p) => p.onSpecial)
      .filter((p) => !grocerProductIdsAlreadyOnList.includes(p.grocerProductId))

    const commonPurchases = [690125, 786816, 749164]
      .filter((id) => !grocerProductIdsAlreadyOnList.includes(id))
      .map((id) => props.allGrocersProducts.find((p) => p.grocerProductId === id))
      .filter((i) => !!i)
    return (
      <div className="shopping-list">
        <h1 className="subtitle">Plan Shopping</h1>

        {commonPurchases.length === 0 && specials.length === 0 && <div>Nothing to suggest at this time</div>}

        {commonPurchases.length > 0 && (
          <div>
            <h2 className="subtitle">Common Purchases</h2>
            {commonPurchases.map((product) => {
              return this.renderProduct(product, false)
            })}
          </div>
        )}

        {specials.length > 0 && (
          <div>
            <h2 className="subtitle">On Special</h2>
            {specials.map((product) => {
              return this.renderProduct(product, true)
            })}
          </div>
        )}
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

export default connect((state, ownProps) => {
  const grocer = state.setting.byName[GROCER] ? findGrocer(state.setting.byName[GROCER]) : null
  const grocerItemsOnList = grocer
    ? new ShoppingItemSelector(state)
        .loadAndCreateObjects((item) => !item.completedAt && item.grocerProductId)
        .filter((item) => item.grocerProduct && item.grocerProduct.grocerId === grocer.getId())
    : []

  const allGrocersProducts = grocer ? new GrocerProductSelector(state).all(grocer.getId()) : []

  return {
    grocer,
    grocerItemsOnList,
    allGrocersProducts,
  }
}, mapDispatchToProps)(Plan)
